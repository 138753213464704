<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>dashboard</md-icon>
          </div>
          <h4 class="title">{{ $t("wms.dashboard") }}</h4>
          <md-button class="md-icon-button md-success add-button" @click="name = null">
            <md-icon>add</md-icon>
          </md-button>
        </md-card-header>
        <md-card-content>
          <md-table v-model="tableData" table-header-color="green">
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="ID">{{ item.id }}</md-table-cell>
              <md-table-cell :md-label="$t('suppliers.name')">{{ item.name }}</md-table-cell>
              <md-table-cell :md-label="$t('suppliers.actions')">
                <span @click="showEditModal(item)"><md-icon>edit</md-icon></span></md-table-cell
              >
            </md-table-row>
          </md-table>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      name: null,
      id: null,
    };
  },
  computed: {},
  methods: {},
  created() {},
};
</script>
<style scoped></style>
