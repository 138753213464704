<template>
  <div class="fixed-plugin drawer-filter" style="border: 1px solid #bbb; border-right: 0 none">
    <div class="dropdown show-dropdown" :class="{ show: isOpen }">
      <a data-toggle="dropdown" @click="toggleDropDown">
        <i class="fa fa-filter fa-2x" style="display: block; padding: 15px"> </i>
      </a>
      <ul class="dropdown-menu" :class="{ show: isOpen }">
        <div class="md-layout">
          <div class="md-layout-item md-size-50">
            <md-field>
              <label>{{ $t("shippingPlan.hasShipmentNote") }}?</label>
              <md-select v-model="filter.hasShipmentNote" name="filter-shipmentNote" id="filter-shipmentNote">
                <md-option value="">{{ $t("shippingPlan.none") }}</md-option>
                <md-option value="true">{{ $t("shippingPlan.yes") }}</md-option>
                <md-option value="false">{{ $t("shippingPlan.no") }}</md-option>
              </md-select>
            </md-field>
            <md-field>
              <label>{{ $t("shippingPlan.hasProduct") }}?</label>
              <md-select v-model="filter.hasProduct" name="filter-hasProduct" id="filter-hasProduct">
                <md-option value="">{{ $t("shippingPlan.none") }}</md-option>
                <md-option value="true">{{ $t("shippingPlan.yes") }}</md-option>
                <md-option value="false">{{ $t("shippingPlan.no") }}</md-option>
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50">
            <md-field>
              <label for="filter-recordStatus">{{ $t("shippingPlan.recordStatus") }}</label>
              <md-select v-model="filter.recordStatusId" name="filter-recordStatus" id="filter-recordStatus">
                <md-option :key="i.id" v-for="i in recordStatusOptions" :value="i.id">{{ getI18N("shippingPlan.statuses.recordStatuses", i.name) }}</md-option>
              </md-select>
            </md-field>
            <vue-tags-input
              class="record-tag-input"
              :placeholder="$t('shippingPlan.addRecordTag')"
              v-model="tag"
              :tags="tags"
              :autocomplete-items="filteredTags"
              @tags-changed="(newTags) => (tags = newTags)"
            />
          </div>
          <div class="md-layout-item md-size-50">
            <md-field>
              <label>{{ $t("shippingPlan.hasDocument") }}?</label>
              <md-select v-model="filter.hasDocument" name="filter-hasDoc" id="filter-hasDoc">
                <md-option value="">{{ $t("shippingPlan.none") }}</md-option>
                <md-option value="true">{{ $t("shippingPlan.yes") }}</md-option>
                <md-option value="false">{{ $t("shippingPlan.no") }}</md-option>
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50">
            <md-field>
              <label for="filter-supplierName">{{ $t("shippingPlan.supplierName") }}</label>
              <md-select v-model="filter.supplierNameId" name="filter-supplierName" id="filter-supplierName">
                <md-option :key="i.id" v-for="i in supplierNameOptions" :value="i.id">{{ i.name }}</md-option>
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50">
            <md-field>
              <label for="filter-containerSize">{{ $t("shippingPlan.containerSize") }}</label>
              <md-select v-model="filter.containerSizeId" name="filter-containerSize" id="filter-containerSize">
                <md-option :key="i.id" v-for="i in containerSizeOptions" :value="i.id">
                  {{ getI18N("shippingPlan.statuses.containerSizes", i.name) }}
                </md-option>
              </md-select>
            </md-field>
            <md-field>
              <label for="filter-carrierName">{{ $t("shippingPlan.carrierName") }}</label>
              <md-select v-model="filter.carrierNameId" name="filter-carrierName" id="filter-carrierName">
                <md-option :key="i.id" v-for="i in carrierNameOptions" :value="i.id">{{ i.name }}</md-option>
              </md-select>
            </md-field>
            <md-field>
              <label for="filter-paymentStatus">{{ $t("shippingPlan.paymentStatus") }}</label>
              <md-select v-model="filter.paymentStatusId" name="filter-paymentStatus" id="filter-paymentStatus">
                <md-option :key="i.id" v-for="i in paymentStatusOptions" :value="i.id">{{ getI18N("shippingPlan.statuses.paymentStatuses", i.name) }}</md-option>
              </md-select>
            </md-field>
            <md-field>
              <label for="filter-shipmentPaidStatus">{{ $t("shippingPlan.shipmentPaidStatus") }}</label>
              <md-select v-model="filter.shipmentPaidStatusId" name="filter-shipmentPaidStatus" id="filter-shipmentPaidStatus">
                <md-option :key="i.id" v-for="i in shipmentPaidStatusOptions" :value="i.id">{{ getI18N("shippingPlan.statuses.shipmentPaidStatuses", i.name) }}</md-option>
              </md-select>
            </md-field>
            <md-field>
              <label for="filter-customsPaidStatus">{{ $t("shippingPlan.customsPaidStatus") }}</label>
              <md-select v-model="filter.customsPaidStatusId" name="filter-customsPaidStatus" id="filter-customsPaidStatus">
                <md-option :key="i.id" v-for="i in customsPaidStatusOptions" :value="i.id">{{ getI18N("shippingPlan.statuses.customsPaidStatuses", i.name) }}</md-option>
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50">
            <md-field>
              <label for="filter-containerArrivalPlace">{{ $t("shippingPlan.containerArrivalPlace") }}</label>
              <md-select v-model="filter.containerArrivalPlaceId" name="filter-containerArrivalPlace" id="filter-containerArrivalPlace">
                <md-option :key="i.id" v-for="i in containerArrivalPlaceOptions" :value="i.id">{{ i.name }}</md-option>
              </md-select>
            </md-field>
            <date-picker
              v-model="containerArrivalDateFromInput"
              format="YYYY-MM-DD"
              type="date"
              :placeholder="$t('shippingPlan.containerArrivalFrom')"
              style="width: 100%; margin: 15px 0"
            ></date-picker>
            <date-picker v-model="containerArrivalDateToInput" format="YYYY-MM-DD" type="date" :placeholder="$t('shippingPlan.containerArrivalTo')" style="width: 100%; margin: 15px 0"></date-picker>
            <md-field>
              <label for="filter-chinaStatus">{{ $t("shippingPlan.chinaStatus") }}</label>
              <md-select v-model="filter.chinaStatusId" name="filter-chinaStatus" id="filter-chinaStatus">
                <md-option :key="i.id" v-for="i in chinaStatusOptions" :value="i.id">{{ getI18N("shippingPlan.statuses.chinaStatuses", i.name) }}</md-option>
              </md-select>
            </md-field>
            <md-field>
              <label for="filter-chinaUser">{{ $t("shippingPlan.chinaUser") }}</label>
              <md-select v-model="filter.chinaUser" name="filter-chinaUser" id="filter-chinaUser">
                <md-option :key="i.id" v-for="i in chinaUserOptions" :value="i.id">{{ i.name }}</md-option>
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item md-size-50">
            <md-field>
              <label for="filter-accountingStatus">{{ $t("shippingPlan.accountingStatus") }}</label>
              <md-input v-model="filter.accountingStatus"></md-input>
            </md-field>
            <date-picker
              v-model="accountingStatusDateFromInput"
              format="YYYY-MM-DD"
              type="date"
              :placeholder="$t('shippingPlan.accountingStatusFrom')"
              style="width: 100%; margin: 15px 0"
            ></date-picker>
            <date-picker v-model="accountingStatusDateToInput" format="YYYY-MM-DD" type="date" :placeholder="$t('shippingPlan.accountingStatusTo')" style="width: 100%; margin: 15px 0"></date-picker>
          </div>
          <div class="md-layout-item md-size-50" style="padding: 30px 15px 15px 15px">
            <md-button class="md-secondary mr-1" @click="clearFilters">{{ $t("shippingPlan.clear") }}</md-button>
            <md-button class="md-success" @click="applyFilters">{{ $t("shippingPlan.apply") }}</md-button>
          </div>
        </div>
      </ul>
    </div>
  </div>
</template>
<script>
import { format } from "date-fns";

import VueTagsInput from "@johmun/vue-tags-input";
import DatePicker from "vue2-datepicker";
import { normalizeStatusName } from "../../mixins/formats";

export default {
  props: {},
  components: {
    VueTagsInput,
    DatePicker,
  },
  data() {
    return {
      isOpen: false,
      tag: "",
      tags: [],
      containerArrivalDateFromInput: null,
      containerArrivalDateToInput: null,
      accountingStatusDateFromInput: null,
      accountingStatusDateToInput: null,
      filter: {
        shipmentRecordId: null,
        recordStatusId: null,
        recordTags: null,
        hasShipmentNote: null,
        hasProduct: null,
        hasDocument: null,
        supplierOrderId: null,
        supplierNameId: null,
        containerId: null,
        containerSizeId: null,
        containerArrivalDateFrom: null,
        containerArrivalDateTo: null,
        containerArrivalPlaceId: null,
        carrierNameId: null,
        paymentStatusId: null,
        shipmentPaidStatusId: null,
        customsPaidStatusId: null,
        chinaStatusId: null,
        chinaUser: null,
        accountingStatus: null,
        accountingStatusDateFrom: null,
        accountingStatusDateTo: null,
      },
    };
  },
  computed: {
    recordStatusOptions() {
      return this.$store.state.shippingData.recordStatuses || [];
    },
    recordTagOptions() {
      return this.$store.state.shippingData.recordTags || [];
    },
    supplierNameOptions() {
      return this.$store.state.shippingData.supplierNames || [];
    },
    containerSizeOptions() {
      return this.$store.state.shippingData.containerSizes || [];
    },
    containerArrivalPlaceOptions() {
      return this.$store.state.shippingData.containerArrivalPlaces || [];
    },
    carrierNameOptions() {
      return this.$store.state.shippingData.carrierNames || [];
    },
    paymentStatusOptions() {
      return this.$store.state.shippingData.paymentStatuses || [];
    },
    shipmentPaidStatusOptions() {
      return this.$store.state.shippingData.shipmentPaidStatuses || [];
    },
    customsPaidStatusOptions() {
      return this.$store.state.shippingData.customsPaidStatuses || [];
    },
    chinaStatusOptions() {
      return this.$store.state.shippingData.chinaStatuses || [];
    },
    linkedProductOptions() {
      return this.$store.state.shippingData.linkedProducts || [];
    },
    chinaUserOptions() {
      return this.$store.state.shippingData.chinaUsers || [];
    },
    filteredTags() {
      const recordTags = this.$store.state.shippingData.recordTags || [];
      const tags = recordTags.map((rt) => ({ text: rt.name }));
      return tags.filter((t) => {
        return t.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
      });
    },
  },
  methods: {
    toggleDropDown() {
      this.isOpen = !this.isOpen;
    },
    closeDropDown() {
      this.isOpen = false;
    },
    clearFilters() {
      this.filter = {};
      this.tag = "";
      this.tags = [];
      this.containerArrivalDateFromInput = null;
      this.containerArrivalDateToInput = null;
      this.accountingStatusDateFromInput = null;
      this.accountingStatusDateToInput = null;
    },
    applyFilters() {
      this.filter.accountingStatusDateFrom = this.accountingStatusDateFromInput ? format(this.accountingStatusDateFromInput, "yyyy-MM-dd") : null;
      this.filter.accountingStatusDateTo = this.accountingStatusDateToInput ? format(this.accountingStatusDateToInput, "yyyy-MM-dd") : null;
      this.filter.containerArrivalDateFrom = this.containerArrivalDateFromInput ? format(this.containerArrivalDateFromInput, "yyyy-MM-dd") : null;
      this.filter.containerArrivalDateTo = this.containerArrivalDateToInput ? format(this.containerArrivalDateToInput, "yyyy-MM-dd") : null;
      this.filter.recordTags = this.tags && this.tags.map((t) => t.text).join(",");
      this.$emit("apply-filters", this.filter);
    },
    getI18N(prefix, statusName) {
      return this.$t(`${prefix}.${normalizeStatusName(statusName)}`);
    },
  },
};
</script>
<style>
.centered-row {
  display: flex;
  height: 100%;
  align-items: center;
}

.button-container .btn {
  margin-right: 10px;
}

.centered-buttons {
  display: flex;
  justify-content: center;
}

.drawer-filter {
  top: 210px !important;
}

.drawer-filter i.fa {
  color: #4caf50 !important;
}
.drawer-filter ul.dropdown-menu {
  width: 500px;
}
</style>
