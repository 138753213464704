<template>
  <div class="md-layout">
    <CSLoading :is-loading="isLoading"></CSLoading>
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>people</md-icon>
          </div>
          <h4 class="title">{{ $t("users.users") }}</h4>
          <md-button class="md-icon-button md-success add-button" @click="showCreateModal">
            <md-icon>add</md-icon>
          </md-button>
        </md-card-header>
        <md-card-content>
          <md-table v-model="tableData" table-header-color="green" class="table-sticky">
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <!-- <md-table-cell md-label="ID">{{ item.userId }}</md-table-cell> -->
              <md-table-cell md-label="Avatar">
                <img v-if="item.avatarContent" :src="buildAvatarLink(item)" width="60" height="60" style="border-radius: 12px" />
              </md-table-cell>
              <md-table-cell :md-label="$t('users.salutation')">{{ item.salutation }}</md-table-cell>
              <md-table-cell :md-label="$t('users.firstName')">{{ item.firstName }}</md-table-cell>
              <md-table-cell :md-label="$t('users.lastName')">{{ item.lastName }}</md-table-cell>
              <md-table-cell :md-label="$t('users.email')">{{ item.email }}</md-table-cell>
              <md-table-cell :md-label="$t('users.roles')">{{ item.roles && item.roles.join(", ") }}</md-table-cell>
              <md-table-cell :md-label="$t('wms.warehouse')">{{ formatWarehousePerm(item) }}</md-table-cell>
              <md-table-cell :md-label="$t('users.status')">{{ item.isActive ? "Active" : "Inactive" }}</md-table-cell>
              <md-table-cell :md-label="$t('users.chinaUser')">{{ item.isChinaUser ? "Yes" : "No" }}</md-table-cell>
              <md-table-cell :md-label="$t('users.actions')">
                <div class="md-table-cell-container cell-actions table-actions">
                  <md-button class="md-icon-button" @click="showEditModal(item)">
                    <md-icon>edit</md-icon>
                  </md-button>
                </div>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
      </md-card>
    </div>
    <modal v-if="createModal" @close="hideCreateModal" class-name="shipping-plan-modal-container">
      <template slot="header">
        <h4 class="modal-title">{{ $t("users.createUser") }}</h4>
        <md-button class="md-simple md-just-icon md-round modal-default-button" @click="hideCreateModal">
          <md-icon>clear</md-icon>
        </md-button>
      </template>

      <template slot="body">
        <ValidationObserver v-slot="{}" ref="createForm">
          <ValidationProvider name="salutation" rules="required" v-slot="{ passed, failed, errors }">
            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
              <label>{{ $t("users.salutation") }}</label>
              <md-input v-model="salutation" type="text"> </md-input>
              <div class="md-error" v-if="errors[0]">
                {{ $t(errors[0]) }}
              </div>
            </md-field>
          </ValidationProvider>
          <ValidationProvider name="firstName" rules="required" v-slot="{ passed, failed, errors }">
            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
              <label>{{ $t("users.firstName") }}</label>
              <md-input v-model="firstName" type="text"> </md-input>
              <div class="md-error" v-if="errors[0]">
                {{ $t(errors[0]) }}
              </div>
            </md-field>
          </ValidationProvider>
          <ValidationProvider name="lastName" rules="required" v-slot="{ passed, failed, errors }">
            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
              <label>{{ $t("users.lastName") }}</label>
              <md-input v-model="lastName" type="text"> </md-input>
              <div class="md-error" v-if="errors[0]">
                {{ $t(errors[0]) }}
              </div>
            </md-field>
          </ValidationProvider>
          <ValidationProvider name="email" rules="required|email" v-slot="{ passed, failed, errors }">
            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
              <label>{{ $t("users.emailAddress") }}</label>
              <md-input v-model="email" type="email"> </md-input>
              <div class="md-error" v-if="errors[0]">
                {{ $t(errors[0]) }}
              </div>
            </md-field>
          </ValidationProvider>

          <ValidationProvider vid="confirmation" name="password" rules="required|min:6" v-slot="{ passed, failed, errors }">
            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
              <label>{{ $t("users.password") }}</label>
              <md-input v-model="password" type="password"> </md-input>
              <div class="md-error" v-if="errors[0]">
                {{ $t(errors[0]) }}
              </div>
            </md-field>
          </ValidationProvider>

          <ValidationProvider rules="required|confirmed:confirmation" v-slot="{ passed, failed, errors }">
            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
              <label>{{ $t("users.confirmPassword") }}</label>
              <md-input v-model="confirmation" type="password"> </md-input>
              <div class="md-error" v-if="errors[0]">
                {{ $t(errors[0]) }}
              </div>
            </md-field>
          </ValidationProvider>
          <ValidationProvider name="roleName" rules="required" v-slot="{ errors }">
            <md-field>
              <label for="roleName">{{ $t("users.role") }}</label>
              <md-select v-model="roleName" name="roleName" id="roleName" multiple>
                <md-option :key="i.id" v-for="i in roleOptions" :value="i.name">{{ i.name }}</md-option>
              </md-select>
              <div class="md-error" v-if="errors[0]">
                {{ $t(errors[0]) }}
              </div>
            </md-field>
          </ValidationProvider>
          <ValidationProvider name="warehouse" rules="required" v-slot="{ errors }">
            <md-field>
              <label>{{ $t("wms.warehouse") }}</label>
              <md-select v-model="warehouseId" name="warehouseId" id="warehouseId">
                <md-option key="all" :value="true">{{ $t("users.allWarehouses") }}</md-option>
                <md-option :key="i.id" v-for="i in warehouseOptions" :value="i.id">{{ i.name }}</md-option>
              </md-select>
              <div class="md-error" v-if="errors[0]">
                {{ $t(errors[0]) }}
              </div>
            </md-field>
          </ValidationProvider>
          <md-switch v-model="isChinaUser" value="true">China User?</md-switch>
          <md-field>
            <label>{{ $t("users.avatar") }}</label>
            <md-file v-model="multipleFile" @change="handleFileUpload($event)"></md-file>
          </md-field>
        </ValidationObserver>
      </template>

      <template slot="footer">
        <md-button class="md-secondary mr-1" @click="hideCreateModal">{{ $t("users.close") }}</md-button>
        <md-button class="md-success" @click="createUser">{{ $t("users.save") }}</md-button>
      </template>
    </modal>

    <modal v-if="editModal" @close="hideEditModal" class-name="user-permissions-modal-container">
      <template slot="header">
        <h4 class="modal-title">{{ $t("users.editUser") }}</h4>
        <md-button class="md-simple md-just-icon md-round modal-default-button" @click="hideEditModal">
          <md-icon>clear</md-icon>
        </md-button>
      </template>

      <template slot="body">
        <ValidationObserver v-slot="{}" ref="editForm">
          <tabs :tab-name="[$t('users.account'), $t('users.permissions')]" color-button="success" content-flex-direction-column>
            <template slot="tab-pane-1">
              <ValidationProvider name="salutation" rules="required" v-slot="{ passed, failed, errors }">
                <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                  <label>{{ $t("users.salutation") }}</label>
                  <md-input v-model="salutation" type="text"> </md-input>
                  <div class="md-error" v-if="errors[0]">
                    {{ $t(errors[0]) }}
                  </div>
                </md-field>
              </ValidationProvider>
              <ValidationProvider name="firstName" rules="required" v-slot="{ passed, failed, errors }">
                <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                  <label>{{ $t("users.firstName") }}</label>
                  <md-input v-model="firstName" type="text"> </md-input>
                  <div class="md-error" v-if="errors[0]">
                    {{ $t(errors[0]) }}
                  </div>
                </md-field>
              </ValidationProvider>
              <ValidationProvider name="lastName" rules="required" v-slot="{ passed, failed, errors }">
                <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                  <label>{{ $t("users.lastName") }}</label>
                  <md-input v-model="lastName" type="text"> </md-input>
                  <div class="md-error" v-if="errors[0]">
                    {{ $t(errors[0]) }}
                  </div>
                </md-field>
              </ValidationProvider>
              <md-field>
                <label>{{ $t("users.avatar") }}</label>
                <md-file v-model="multipleFile" @change="handleFileUpload($event)"></md-file>
              </md-field>
              <div v-if="avatarContent">
                <img :src="buildAvatarLink({ userId, avatarContent })" style="width: 100px" />
              </div>
            </template>
            <template slot="tab-pane-2">
              <div class="md-layout">
                <div class="md-layout-item">
                  <ValidationProvider name="roleName" rules="required" v-slot="{ errors }">
                    <md-field>
                      <label for="roleName">{{ $t("users.role") }}</label>
                      <md-select v-model="roleName" name="roleName" id="roleName" multiple>
                        <md-option :key="i.id" v-for="i in roleOptions" :value="i.name">{{ i.name }}</md-option>
                      </md-select>
                      <div class="md-error" v-if="errors[0]">
                        {{ $t(errors[0]) }}
                      </div>
                    </md-field>
                  </ValidationProvider>
                  <ValidationProvider name="warehouse" rules="required" v-slot="{ errors }">
                    <md-field>
                      <label>{{ $t("wms.warehouse") }}</label>
                      <md-select v-model="warehouseId" name="warehouseId" id="warehouseId">
                        <md-option key="all" :value="true">{{ $t("users.allWarehouses") }}</md-option>
                        <md-option :key="i.id" v-for="i in warehouseOptions" :value="i.id">{{ i.name }}</md-option>
                      </md-select>
                      <div class="md-error" v-if="errors[0]">
                        {{ $t(errors[0]) }}
                      </div>
                    </md-field>
                  </ValidationProvider>
                  <md-switch v-model="isChinaUser" value="true">{{ $t("users.chinaUser") }}?</md-switch>

                  <label style="display: block; text-align: left"
                    ><strong>{{ $t("users.permissions") }}</strong></label
                  >
                  <UserPermissions :userData="user" @userPerimissionsUpdated="updateUserPermissions"></UserPermissions>
                </div>
              </div>
            </template>
          </tabs>
        </ValidationObserver>
      </template>

      <template slot="footer">
        <md-button class="md-secondary mr-1" @click="hideEditModal">{{ $t("users.close") }}</md-button>
        <md-button class="md-success" @click="editUser">{{ $t("users.save") }}</md-button>
      </template>
    </modal>
  </div>
</template>
<script>
import { cloneDeep, remove } from "lodash";

import { Modal, Tabs } from "@/components";
import CSLoading from "../../components/Loading/CSLoading.vue";
import UserPermissions from "./UserPermissions.vue";
import axiosFactory from "../../services/axios.factory";
import config from "../../config";
import { EventBus } from "@/helpers/event-bus";

export default {
  components: {
    Modal,
    Tabs,
    CSLoading,
    UserPermissions,
  },
  data() {
    return {
      isLoading: true,
      createModal: false,
      editModal: false,
      salutation: null,
      lastName: null,
      firstName: null,
      email: null,
      password: null,
      confirmation: null,
      roleName: null,
      warehouseId: null,
      userId: null,
      isChinaUser: null,
      multipleFile: null,
      uploadFile: null,
      avatar: null,
      avatarContent: null,
      tableColumnPermissions: {},
      menuPermissions: [],
      user: null,
    };
  }, //
  computed: {
    roleOptions() {
      return this.$store.state.users.roles || [];
    },
    warehouseOptions() {
      return this.$store.state.warehouses.warehouses || [];
    },
    tableData() {
      return this.$store.state.users.users || [];
    },
  },
  methods: {
    hideCreateModal() {
      this.createModal = false;
    },
    hideEditModal() {
      this.editModal = false;
    },
    showCreateModal() {
      this.email = null;
      this.password = null;
      this.salutation = null;
      this.firstName = null;
      this.lastName = null;
      this.roleName = null;
      this.warehouseId = null;
      this.isChinaUser = null;
      this.multipleFile = null;
      this.createModal = true;
    },
    showEditModal(user) {
      this.salutation = user.salutation;
      this.firstName = user.firstName;
      this.lastName = user.lastName;
      this.roleName = user.roles && user.roles;
      this.warehouseId = user.allowAllWarehouses ? true : user.allowWarehouseId;
      this.userId = user.userId;
      this.isChinaUser = user.isChinaUser ? "true" : "false";
      this.avatarContent = user.avatarContent;
      this.multipleFile = null;
      axiosFactory()
        .get(`/users/${user.userId}`)
        .then((res) => {
          const u = res.data.data;
          this.user = u;
          this.tableColumnPermissions = u.tableColumnPermissions;
          this.menuPermissions = u.menuPermissions;
        })
        .finally(() => {
          this.isLoading = false;
          this.editModal = true;
        });
    },
    buildAvatarLink({ userId, avatarContent }) {
      return `${config.baseURLApi}/api/files/avatar/${userId}?file=${avatarContent}`;
    },
    createUser() {
      this.$refs.createForm.validate().then((success) => {
        if (!success) {
          return;
        }

        const formData = new FormData();
        formData.append("salutation", this.salutation);
        formData.append("lastName", this.lastName);
        formData.append("firstName", this.firstName);
        formData.append("email", this.email);
        formData.append("password", this.password);
        formData.append("roles", this.roleName && this.roleName.join(","));
        formData.append("avatars", this.uploadFile);
        formData.append("isChinaUser", this.isChinaUser === "true" ? "true" : "false");

        if (this.warehouseId != null && typeof this.warehouseId === "number") {
          formData.append("allowWarehouseId", this.warehouseId);
        } else if (this.warehouseId === true) {
          formData.append("allowAll", true);
        }

        this.isLoading = true;
        axiosFactory()
          .post(`/users`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((_) => {
            this.$notify({
              timeout: 2500,
              message: this.$t("users.messages.created"),
              icon: "add_alert",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "success",
            });

            return this.$store.dispatch("users/getUsers");
          })
          .finally(() => {
            this.hideCreateModal();
            this.isLoading = false;
          });
      });
    },
    editUser() {
      this.$refs.editForm.validate().then((success) => {
        if (!success) {
          return;
        }

        const _tableColumnPermissions = cloneDeep(this.tableColumnPermissions);
        const userPermissions = Object.entries(_tableColumnPermissions).reduce((acc, [table, permissions]) => {
          let Read = permissions?.Read ? [...permissions.Read] : undefined;
          let Write = permissions?.Write ? [...permissions.Write] : undefined;
          let LimitRead = permissions?.LimitRead ? [...permissions.LimitRead] : undefined;

          if (permissions?.Write?.includes("ALL")) {
            Read = undefined;
            LimitRead = undefined;
          }
          if (Read?.includes("ALL")) {
            LimitRead = ["ALL"];
          }
          acc[table] = { Read, Write, LimitRead };
          return acc;
        }, {});

        const formData = new FormData();
        formData.append("userId", this.userId);
        formData.append("salutation", this.salutation);
        formData.append("lastName", this.lastName);
        formData.append("firstName", this.firstName);
        formData.append("roles", this.roleName && this.roleName.join(","));
        formData.append("avatars", this.uploadFile);
        formData.append("isChinaUser", this.isChinaUser === "true" ? "true" : "false");
        formData.append("menuPermissions", JSON.stringify(this.menuPermissions));
        formData.append("tableColumnPermissions", JSON.stringify(userPermissions));

        if (this.warehouseId != null && typeof this.warehouseId === "number") {
          formData.append("allowWarehouseId", this.warehouseId);
        } else if (this.warehouseId === true) {
          formData.append("allowAll", true);
        }

        this.isLoading = true;
        axiosFactory()
          .put(`/users`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((_) => {
            this.$notify({
              timeout: 2500,
              message: this.$t("users.messages.updated"),
              icon: "add_alert",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "success",
            });

            return this.$store.dispatch("users/getUsers");
          })
          .finally(() => {
            this.hideEditModal();
            this.isLoading = false;
          });
      });
    },
    updateUserPermissions(permissionsUpdated) {
      this.menuPermissions = permissionsUpdated.menuPermissions;
      this.tableColumnPermissions = permissionsUpdated.tableColumnPermissions;
    },
    handleFileUpload(event) {
      this.uploadFile = null;
      this.uploadFile = event.target.files.length > 0 && event.target.files[0];
    },
    formatWarehousePerm(item) {
      if (!this.warehouseOptions.length) {
        return "";
      }

      if (item.allowAllWarehouses) {
        return this.$t("users.allWarehouses");
      }

      if (item.allowWarehouseId) {
        const warehouse = this.warehouseOptions.find((i) => i.id === item.allowWarehouseId);
        if (warehouse) {
          return warehouse.name;
        }

        return "ID: " + item.allowWarehouseId;
      }

      return "N/A";
    },
  },
  created() {
    Promise.all([this.$store.dispatch("users/getUsers"), this.$store.dispatch("users/getRoles"), this.$store.dispatch("warehouses/getWarehouses")]).finally((_) => {
      this.isLoading = false;
    });
  },
  updated() {
    EventBus.$emit("scroll:changed");
  },
};
</script>
<style scoped>
.table-transparent {
  background-color: transparent !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mr-1 {
  margin-right: 15px;
}
</style>
