import TokenService from "../services/token.service";

export const isAuthenticated = () => {
  const token = TokenService.getToken();
  if (!token) return false;
  return true;
};

export const isAdmin = () => {
  const user = TokenService.getUser();
  return user && user.roles && user.roles.includes("Administrators");
};

export const canView = (menu) => {
  const user = TokenService.getUser();
  return user && user.menuPermissions && user.menuPermissions.includes(menu);
};

export const hasPermission = (permissionName, tableName, columnName) => {
  if (isAdmin()) return true;

  const user = TokenService.getUser();
  const tableColumnPermissions = user && user.tableColumnPermissions;
  if (!tableColumnPermissions) {
    return false;
  }

  const tableColumnPermission = tableColumnPermissions[tableName];
  if (!tableColumnPermission) {
    return false;
  }

  if (permissionName === "Write" && tableColumnPermission["Write"]) {
    if (tableName === "Products") {
      const writeColumns = tableColumnPermission["Write"];
      if (!writeColumns) {
        return false;
      }

      const writePermission = writeColumns.find((a) => a === columnName);
      return !!writePermission;
    }

    return true;
  }

  if ((permissionName === "Read" || permissionName === "LimitRead") && (tableColumnPermission["Write"] || tableColumnPermission["Read"])) {
    return true;
  }

  const columns = tableColumnPermission["LimitRead"];
  if (!columns) {
    return false;
  }

  const permission = columns.find((a) => a === columnName);

  return !!permission;
};

export const assignedWarehouse = () => {
  const user = TokenService.getUser();

  if (user.allowAllWarehouses) {
    return null;
  }

  if (user.allowWarehouseId != null) {
    return {
      id: user.allowWarehouseId,
      // name: "Piesport",
    };
  }

  return null;
};

export const AuthMixin = {
  methods: {
    isAuthenticated,
    isAdmin,
    canView,
    hasPermission,
    assignedWarehouse,
  },
};
