import PostService from "../services/post.service";

const initialState = {
  posts: [],
};

export default {
  namespaced: true,
  state: initialState,
  actions: {
    create(_, opts) {
      return PostService.create(opts).then(
        (result) => {
          return Promise.resolve(result);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    update(_, opts) {
      return PostService.update(opts).then(
        (result) => {
          return Promise.resolve(result);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    delete(_, id) {
      return PostService.delete(id).then(
        (result) => {
          return Promise.resolve(result);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getAll({ commit }) {
      return PostService.getAll().then(
        (result) => {
          commit("getPostsSuccess", result);
          return Promise.resolve(result);
        },
        (error) => {
          commit("getPostsFailure");
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    getPostsSuccess(state, result) {
      state.posts = result;
    },
    getPostsFailure(state) {
      state.posts = null;
    },
  },
};
