<template>
  <div class="md-layout">
    <CSLoading :is-loading="isLoading"></CSLoading>
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>download</md-icon>
        </div>
        <h4 class="title">{{ $t("layout.wms.fileExplorer") }}</h4>
      </md-card-header>
      <md-card-content>
        <md-tabs @md-changed="handleChangeTab">
          <md-tab id="ExportInventoryManagement" :md-label="$t('fileExplorer.ExportInventoryManagement')" />
          <md-tab id="ImportShipping" :md-label="$t('fileExplorer.ImportShipping')" />
          <md-tab id="ExportOrder" :md-label="$t('fileExplorer.ExportOrder')" />
          <md-tab id="ExportProduct" :md-label="$t('fileExplorer.ExportProduct')" />
          <md-tab id="ExportInventory" :md-label="$t('fileExplorer.ExportInventory')" />
        </md-tabs>
        <md-field md-clearable>
          <label>{{ $t("products.keywords") }}</label>
          <md-input v-model="search" @input="debounceApplyFilters"></md-input>
        </md-field>
        <md-table v-model="tableData" table-header-color="green" class="table-sticky" md-sort="createdAt" md-sort-order="desc" @sort="sortData">
          <md-table-empty-state md-label="No files found" :md-description="`No file found. Try a different search term.`"> </md-table-empty-state>
          <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell :md-label="$t('suppliers.name')">
              <!-- <md-icon v-if="item.type === 'file'">description</md-icon>
            <md-icon v-else>folder</md-icon> -->
              {{ item.name }}</md-table-cell
            >
            <md-table-cell :md-label="$t('fileExplorer.size')">{{ item.size | formatBytes }}</md-table-cell>
            <md-table-cell :md-label="$t('fileExplorer.createdAt')">{{ item.createdAt | formatDateTime }}</md-table-cell>
            <md-table-cell :md-label="$t('suppliers.actions')">
              <md-button class="md-icon-button" @click="download(item)" :disabled="isLoading"><md-icon>download</md-icon></md-button></md-table-cell
            >
          </md-table-row>
        </md-table>
      </md-card-content>
    </md-card>
  </div>
</template>
<script>
import { debounce } from "lodash";
import fileService from "../../services/file.service";
import CSLoading from "../../components/Loading/CSLoading.vue";

export default {
  components: { CSLoading },
  data() {
    return {
      path: "path/to/file",
      isLoading: false,
      items: [],
      currentPage: 1,
      pageSize: 50,
      orderBy: "createdAt",
      ascending: false,
      totalItems: 0,
      search: "",
      fileType: "ExportInventoryManagement",
    };
  },
  computed: {
    tableData() {
      return this.$store.state.files.items || [];
    },
    confirmDeleteMessage() {
      return this.$t("news.confirmDeleteMessage", {
        headline: this.post?.title,
      });
    },
  },
  methods: {
    handleChangeTab(tab) {
      this.fileType = tab;
      this.debounceApplyFilters();
    },
    debounceApplyFilters: debounce(function () {
      this.getData();
    }, 300),
    async download(item) {
      this.isLoading = true;
      try {
        const file = await fileService.downloadFile(item.name, this.fileType);
        const url = window.URL.createObjectURL(new Blob([file]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", item.name);
        document.body.appendChild(link);
        link.click();
        this.isLoading = false;
        this.$notify({
          timeout: 2500,
          message: this.$t("fileExplorer.downloadSuccess"),
          icon: "download",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "success",
        });
      } catch (e) {
        this.isLoading = false;
        this.$notify({
          timeout: 2500,
          message: this.$t("fileExplorer.downloadFailed"),
          icon: "error",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger",
        });
      }
    },
    sortData(args) {
      this.orderBy = orderBy;
      this.ascending = ascending;
      this.getData();
    },
    getData(params) {
      this.isLoading = true;
      const _params = {
        search: this.search,
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        orderBy: this.orderBy,
        ascending: this.ascending,
        fileType: this.fileType,
      };
      this.$store.dispatch("files/getAll", { ..._params, ...params });
      this.isLoading = false;
    },
  },
  created() {
    Promise.all([this.getData()]).finally((_) => {
      this.isLoading = false;
    });
  },
};
</script>

<style scoped></style>
