import AuthService from "../services/auth.service";
import TokenService from "../services/token.service";

export default {
  namespaced: true,
  state: {
    user: null,
  },
  mutations: {
    loginFailure(state, payload) {
      state.isFetching = false;
      state.errorMessage = payload;
      state.user = null;
    },
    loginSuccess(state, user) {
      state.isFetching = false;
      state.errorMessage = "";
      state.user = user;
    },
    loginRequest(state) {
      state.isFetching = true;
    },
    logoutSuccess(state) {
      state.isFetching = false;
      state.errorMessage = "";
      state.user = null;
    },
  },
  actions: {
    loginUser({ commit }, creds) {
      return AuthService.login(creds).then(
        (user) => {
          commit("loginSuccess", user);
          return Promise.resolve(user);
        },
        (error) => {
          commit("loginFailure");
          return Promise.reject(error);
        }
      );
    },
    logoutUser({ commit }) {
      AuthService.logout();
      commit("logoutSuccess");
      return Promise.resolve(true);
    },
    registerUser(_, model) {
      return AuthService.register(model).then(
        (user) => {
          return Promise.resolve(user);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    forgotPassword({ commit }, opts) {
      commit("toggleFetching", true);
      return AuthService.forgotPassword(opts).then(
        (user) => {
          commit("toggleFetching", false);
          return Promise.resolve(user);
        },
        (error) => {
          commit("toggleFetching", false);
          return Promise.reject(error);
        }
      );
    },
    confirmForgotPassword({ commit }, opts) {
      commit("toggleFetching", true);
      return AuthService.confirmForgotPassword(opts).then(
        (user) => {
          commit("toggleFetching", false);
          return Promise.resolve(user);
        },
        (error) => {
          commit("toggleFetching", false);
          return Promise.reject(error);
        }
      );
    },
  },
};
