import axiosFactory from "./axios.factory";
import BaseService from "./base.service";

/**
 * File Service
 * @class FileService
 * @extends BaseService
 */
class FileService extends BaseService {
  async downloadFile(fileName, fileType) {
    return axiosFactory()
      .get(`${this.entity}/${fileType}/download`, {
        responseType: "blob",
        params: { name: fileName },
      })
      .then((res) => res.data);
  }
}

export default new FileService("files/import_export_data");
