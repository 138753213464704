var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:[{ 'nav-open': _vm.$sidebar.showSidebar }, { rtl: _vm.$route.meta.rtlActive }]},[_c('notifications'),_c('side-bar',{attrs:{"active-color":_vm.sidebarBackground,"background-image":_vm.sidebarBackgroundImage,"data-background-color":_vm.sidebarBackgroundColor}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('layout.dashboard'), icon: 'dashboard', path: '/app/dashboard' }}}),(_vm.canView('ShippingPlans'))?_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('layout.shippingPlans'), icon: 'local_shipping', path: '/app/shipping-plans' }}}):_vm._e(),(_vm.canView('Products'))?_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('layout.products'), icon: 'package_2', iconOutlined: true, path: '/app/products' }}}):_vm._e(),(_vm.canView('Orders'))?_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('layout.orders'), icon: 'grade', iconOutlined: false, path: '/app/orders' }}}):_vm._e(),(_vm.canView('Suppliers'))?_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('layout.suppliers'),
          icon: 'precision_manufacturing',
          iconOutlined: true,
          path: '/app/suppliers',
        }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('layout.wms.wms'), disableCollapse: true, icon: 'warehouse' }}},[_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('layout.wms.dashboard'), icon: 'dashboard', path: '/app/wms-dashboard' }}}),(_vm.canView('Inventories'))?_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('layout.wms.inventory'), icon: 'inventory_2', path: '/app/wms-inventories' }}}):_vm._e(),(_vm.canView('Warehouses'))?_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('layout.wms.warehouses'), icon: 'shelves', path: '/app/wms-warehouses' }}}):_vm._e(),(_vm.canView('StorageLocations'))?_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('layout.wms.storagelocations'),
            icon: 'home_storage',
            iconOutlined: true,
            path: '/app/wms-storage-locations',
          }}}):_vm._e(),(_vm.canView('InventoryManagementExport'))?_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('layout.wms.fileExplorer'),
            icon: 'download',
            iconOutlined: false,
            path: '/app/wms-management-export',
          }}}):_vm._e(),(_vm.canView('WarehouseHistory'))?_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('layout.wms.history'),
            icon: 'history',
            path: '/app/wms-history',
          }}}):_vm._e()],1),(_vm.isAdminUser)?_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('layout.users'), icon: 'people', path: '/app/users' }}}):_vm._e()],1)],2),_c('div',{staticClass:"main-panel"},[_c('TopNavbar'),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }